import * as React from 'react'
import Link from '../Link'
import './style.scss'

interface HorizontalListProps {
  items: string[]
  active?: string
  className?: string
  onChange: (value: string) => void
  isItLink?: boolean
}

const HorizontalList: React.FC<HorizontalListProps> = ({
  items,
  active,
  className,
  onChange,
  isItLink = false
}) => {

  const listItem = (item: string): JSX.Element => {
    return (
      <li className={`${item === active ? 'text-primary' : 'text-black'} 
            cursor-pointer hover:text-gray-70 mt-mobile-xs lg:mt-xs ${className || ''}
            `}
      >
        <span onClick={() => onChange(item)} >
          {item === active && <span className="hidden lg:inline lg:mr-xxs">+</span>}
          <span className="mr-mobile-xs lg:mr-s">{item}</span>
        </span>
      </li>
    )
  }
  return (
    <div className="HorizontalList">
      {isItLink ?
        <ul className="flex flex-wrap">
          {items.map((item, index) => {
            const itemUrl = item === 'All' ? '' : item.replace(/\s+/g, '-').toLowerCase()
            return (
              <Link url={`/blog/${itemUrl}`} key={index}>
                {listItem(item)}
              </Link>
            )
          })
          }
        </ul> :
        <ul className="flex flex-wrap">
          {items.map((item, index) => (
            <div key={index}>
              {listItem(item)}
            </div>
          ))}
        </ul>
      }
    </div>
  )
}

export default HorizontalList
