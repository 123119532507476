import * as React from 'react'
import { IsMobileContext } from '../../../common/Context'
import Plus from '../../../images/plus.svg'
import Minus from '../../../images/minus.svg'
import './style.scss'

interface PlusMinusIconProps {
  className?: string
  active: boolean
}

const PlusMinusIcon: React.FC<PlusMinusIconProps> = ({
  className,
  active,
}) => {
  const { isMobile } = React.useContext(IsMobileContext)
  const iconSize = isMobile ? '5vw' : '1.25vw'

  return (
    <div className={`PlusMinusIcon relative ${className || ''}`}
      style={{width: iconSize, height: iconSize}}
    >
      <Plus 
        className={`absolute
          transition-all duration-500 ease-out transform
          ${active ? 'opacity-0 rotate-90' : 'opacity-100 rotate-0'}`}
        style={{width: iconSize, height: iconSize}}
      />
      <Minus 
        className={`absolute
          transition-all duration-500 ease-out transform
          ${active ? 'opacity-100 rotate-0' : 'opacity-0 -rotate-90'}`}
        style={{width: iconSize, height: iconSize}}
      />
    </div>
  )
}

export default PlusMinusIcon
