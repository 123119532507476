import { graphql, useStaticQuery } from 'gatsby'
import { ImageItem } from '@kentico/gatsby-kontent-components/dist/image-element'
import { filterPageDataByLanguage } from '../../common/serviceHelpers'
import _get from 'lodash/get'

/* Kentico model: Process Section */
export interface KenticoProcessSection extends KontentItem {
  title: KontentRichTextElement
  image_or_video_desktop: KontentAssetElement,
  image_or_video_mobile: KontentAssetElement,
}

export class ProcessSection {
  title: string
  imageDesktop: ImageItem
  videoDesktopUrl: string
  imageMobile: ImageItem
  videoMobileUrl: string

  constructor(data: KenticoProcessSection) {
    this.title = _get(data, 'title.value', '')
    const desktopAssetType = _get(data, 'image_or_video_desktop.value[0].type', '')
    this.imageDesktop = desktopAssetType.includes('image') ? _get(data, 'image_or_video_desktop.value[0]') : ''
    this.videoDesktopUrl = desktopAssetType.includes('video') ? _get(data, 'image_or_video_desktop.value[0].url') : ''
    const mobileAssetType = _get(data, 'image_or_video_mobile.value[0].type', '')
    this.imageMobile = mobileAssetType.includes('image') ? _get(data, 'image_or_video_mobile.value[0]') : ''
    this.videoMobileUrl = mobileAssetType.includes('video') ?  _get(data, 'image_or_video_mobile.value[0].url') : ''
  }
}

export const processSectionFragment = graphql`
query ProcessSectionQuery {
  allKontentItemPage {
    nodes {
      elements {
        content {
          value {
            ... on kontent_item_process_section {
              elements {
                ...kontent_item_process_section_elementsFragment
              }
              preferred_language
              system {
                codename
              }
            }
          }
        }
      }
      system {
        codename
      }
    }
  }
}`

export const processSectionElementsFragment = graphql`
  fragment kontent_item_process_section_elementsFragment on kontent_item_process_section_elements {
    title {
      value
    }
    image_or_video_desktop {
      value {
        url
        description
        height
        name
        size
        type
        width
      }
    }
    image_or_video_mobile {
      value {
        url
        description
        height
        name
        size
        type
        width
      }
    }
  }
`

export const useProcessSectionData = (language: string, pageCodename: string, sectionCodename = '') => {
  const data = useStaticQuery(processSectionFragment)
  const processSectionData: KenticoProcessSection = filterPageDataByLanguage(data.allKontentItemPage, pageCodename, sectionCodename, language)
  return new ProcessSection(processSectionData)
}