import * as React from 'react'
import { graphql, PageProps } from 'gatsby'

//components
import Layout from '../components/Layout'
import Navbar from '../components/Navbar'
import HeroSection from '../components/HeroSection'
import HorizontalList from '../components/HorizontalList'
import AccordionSection from '../components/AccordionSection'
import CategoryAccordionSection from '../components/CategoryAccordionSection'
import ProcessSection from '../components/ProcessSection'
import ClientsSection from '../components/ClientsSection'
import ImageVideoSection from '../components/ImageVideoSection'
import FloatingButton from '../components/FloatingButton'
import Footer from '../components/Footer'

//models, hooks
import { IsMobileContext, LanguageContext } from '../common/Context'
import { useScroll } from '../hooks/useScroll'
import { useSeoData } from '../components/SeoComponent/model'
import { useLinksGridData, LinksGrid } from '../components/LinksGrid/model'
import { useNavbarData, Navbar as NavbarClass } from '../components/Navbar/model'
import { useHeroSectionData, HeroSection as HeroSectionClass } from '../components/HeroSection/model'
import { useAccordionSectionData, AccordionSection as AccordionSectionClass } from '../components/AccordionSection/model'
import { useAllCategoryAccordionSectionsData, CategoryAccordionSection as CategoryAccordionSectionClass } from '../components/CategoryAccordionSection/model'
import { useProcessSectionData, ProcessSection as ProcessSectionClass } from '../components/ProcessSection/model'
import { useClientsSectionData, ClientsSection as ClientsSectionClass } from '../components/ClientsSection/model'
import { useImageVideoSectionData, ImageVideoSection as ImageVideoSectionClass } from '../components/ImageVideoSection/model'

//other
import { convertToSlug } from '../common/serviceHelpers'
import withSSR from '../hoc/withSSR'
import './services.scss'


const ServicesPage: React.FC<PageProps> = ({ location: { pathname } }) => {
  const { language: initialLanguage } = React.useContext(LanguageContext)
  const [language, setLanguage] = React.useState(initialLanguage)

  /*  For data collecting hooks below (e.g. useLinksGridData), 
  You need to know page codename from kentico and optionally section codename.
  Data service is searching query for this elements and collects the right data to components  */
  const pageCodename = 'services_page'
  const seoData = useSeoData(language, pageCodename, pathname)
  const allCategoryAccordionSectionsData = useAllCategoryAccordionSectionsData(language, pageCodename)
  const categories = allCategoryAccordionSectionsData.reduce((allCategories: string[], sectionData) => {
    allCategories.push(sectionData.category)
    return allCategories
  }, [])
  const pageData = {
    linksGridData: useLinksGridData(language, pageCodename),
    navbarData: useNavbarData(language, pageCodename, pathname),
    heroSectionData: useHeroSectionData(language, pageCodename),
    accordionSectionData: useAccordionSectionData(language, pageCodename),
    processSectionData: useProcessSectionData(language, pageCodename),
    clientsSectionData: useClientsSectionData(language, pageCodename),
    imageVideoSectionData: useImageVideoSectionData(language, pageCodename),
    allCategoryAccordionSectionsData,
    categories
  }

  const { scrollTo } = useScroll({})
  React.useEffect(() => {
    if (typeof window !== 'undefined' && window.location.hash) {
      const element = document.querySelector(`${window.location.hash}`) as HTMLElement
      scrollTo(element)
    }
  }, [scrollTo])

  return (
    <Layout seoData={seoData} language={language} setLanguage={setLanguage}>
      <ServicesPageTemplateWithSSR pageData={pageData} />
    </Layout>
  )
}

interface ServicesPageProps {
  isMobile: boolean
  pageData: {
    linksGridData: LinksGrid
    navbarData: NavbarClass
    heroSectionData: HeroSectionClass
    accordionSectionData: AccordionSectionClass | null
    allCategoryAccordionSectionsData: CategoryAccordionSectionClass[]
    categories: string[]
    processSectionData: ProcessSectionClass
    clientsSectionData: ClientsSectionClass
    imageVideoSectionData: ImageVideoSectionClass
  }
}

const ServicesPageTemplate: React.FC<ServicesPageProps> = ({
  isMobile,
  pageData: {
    linksGridData,
    navbarData,
    heroSectionData,
    accordionSectionData,
    allCategoryAccordionSectionsData,
    categories,
    processSectionData,
    clientsSectionData,
    imageVideoSectionData,
  }
}) => {
  const { scrollTo } = useScroll({})

  const scrollMargin = isMobile ? '-16vw' : '-4vw'

  const CategoryAccordionSections: React.FC = () => <>
    {allCategoryAccordionSectionsData.map((section, index) => (
      <React.Fragment key={index}>
        <div id={convertToSlug(section.category)} style={{ scrollMargin }}>
          <CategoryAccordionSection {...section} />
        </div>
      </React.Fragment>
    ))}
  </>

  const tagChangedHandler = (category: string) => {
    const categorySlug = convertToSlug(category)
    const newUrl = new URL(window.location.href)
    newUrl.hash = `#${categorySlug}`
    window.history.pushState({}, '', newUrl.href)
    if (typeof document !== 'undefined') {
      const element = document.getElementById(categorySlug)
      scrollTo(element)
    }
  }

  return (
    <IsMobileContext.Provider value={{ isMobile }}>
      <header>
        <Navbar {...linksGridData} {...navbarData} />
      </header>
      <main className="ServicesPage">
        <HeroSection {...heroSectionData} headingColumns={{ desktop: 6, mobile: 12 }} descriptionColumns={{ desktop: 6, mobile: 12 }}>
          <div className="mt-mobile-l lg:mt-l">
            <HorizontalList
              className="FontS"
              items={categories}
              onChange={tagChangedHandler}
            />
          </div>
        </HeroSection>
        {accordionSectionData && <AccordionSection {...accordionSectionData} />}
        <CategoryAccordionSections />
        <ProcessSection {...processSectionData} />
        <ClientsSection {...clientsSectionData} />
        <ImageVideoSection {...imageVideoSectionData} />
      </main>
      <footer>
        <Footer {...linksGridData} />
      </footer>
      <FloatingButton />
    </IsMobileContext.Provider>
  )
}
const ServicesPageTemplateWithSSR = withSSR<ServicesPageProps>(ServicesPageTemplate)

export default ServicesPage

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
