import * as React from 'react'
import AccordionColumn from './AccordionColumn'
import { AccordionSection as AccordionSectionClass } from './model'
import './style.scss'
import { AnimationService } from '../../common/AnimationService'
import { IsMobileContext } from '../../common/Context'

interface AccordionSectionProps extends AccordionSectionClass {
  className?: string
  titleClass?: string
  textClass?: string
}

const AccordionSection: React.FC<AccordionSectionProps> = ({
  title,
  text,
  backgroundColor,
  columns,
  className,
  titleClass,
  textClass,
}) => {

  const { isMobile } = React.useContext(IsMobileContext)

  const titleRef = React.useRef<HTMLDivElement>(null)
  const textRef = React.useRef<HTMLDivElement>(null)
  const columnsRefs = React.useRef<Array<HTMLDivElement>>([])
  React.useEffect(() => {
    if (isMobile !== undefined) {
      AnimationService.slideUp(titleRef.current)
      AnimationService.slideUp(textRef.current)
      if (isMobile) {
        for (const el of columnsRefs.current) {
          AnimationService.slideUp(el)
        }
      } else {
        AnimationService.slideSequenceUp(columnsRefs.current)
      }
    }
  }, [isMobile])
  const gridCols = ['', 'lg:grid-cols-1', 'lg:grid-cols-2', 'lg:grid-cols-3']
  const nonEmptyColumns = columns.filter(column => column.title || column.text || column.items.length)
  const nonEmptyColumnsCount = nonEmptyColumns.length

  return (
    <section
      className={`AccordionSection GlobSectPadSpacing px-m ${className || ''}`}
      style={{ backgroundColor: backgroundColor }}
    >
      <div className="SpacingAdjust pt-mobile-l lg:pt-l"></div>
      <div className='grid grid-cols-12 gap-m'>
        {title && <div ref={titleRef} className="FontXL lg:col-start-1 col-span-12 lg:col-span-6 pb-mobile-s lg:pb-s">{title}</div>}
        {text && <div ref={textRef} className="FontL lg:col-start-1 col-span-12 lg:col-span-6 pb-mobile-l lg:pb-m">{text}</div>}
      </div>
      <div className={`grid grid-col-1 ${gridCols[nonEmptyColumnsCount]} gap-x-m`}>
        {nonEmptyColumns.map((column, i) => (
          <div key={i}>
            {i > 0 && <div className="pt-mobile-l lg:pt-0"></div>}
            <AccordionColumn
              key={i}
              ref={(el: HTMLDivElement) => columnsRefs.current[i] = el}
              title={column.title}
              text={column.text}
              items={column.items}
              titleClass={titleClass}
              textClass={textClass}
            />
          </div>
        ))}
      </div>
      {backgroundColor && <div className="pt-mobile-m lg:pt-m" style={{ backgroundColor: backgroundColor }}></div>}
    </section>
  )
}

export const LandingPageAccordionSection: React.FC<AccordionSectionProps> = ({
  title,
  text,
  backgroundColor,
  columns,
  className,
  titleClass = 'FontL',
  textClass = 'FontM',
}) => (
  <AccordionSection
    title={title}
    text={text}
    backgroundColor={backgroundColor}
    columns={columns}
    className={className}
    titleClass={titleClass}
    textClass={textClass}
  />
)

export default AccordionSection
