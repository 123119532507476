import { graphql } from 'gatsby'
import _get from 'lodash/get'

/* Kentico model: Accordion Item Element */
export interface KenticoAccordionItemElement extends KontentItem {
  elements: {
    heading: KontentTextElement
    text: KontentTextElement
  }
}

export class AccordionItem {
  title: string
  content: string

  constructor(rawData: KenticoAccordionItemElement) {
    this.title = _get(rawData, 'elements.heading.value', '')
    this.content = _get(rawData, 'elements.text.value', '')
  }
}

export const accordionFragment = graphql`
fragment accordion_column_itemFragment on kontent_item_modular_content_element_value {
  value {
    ... on kontent_item_accordion_item {
      elements {
        heading {
          value
        }
        text {
          value
        }
      }
    }
  }
}`