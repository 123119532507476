import * as React from 'react'
import AccordionItem from './AccordionItem'
import { AccordionItem as AccordionItemClass } from './AccordionItem/model'
import './style.scss'

interface AccordionProps {
  data: AccordionItemClass[]
  fullWidthMode: boolean
}

const Accordion: React.FC<AccordionProps> = ({ data, fullWidthMode }) => {
  const [selectedItem, setSelectedItem] = React.useState<number>()

  const toggleHandler = (active: boolean, accordionItemId: number) => {
    if (active) {
      setSelectedItem(accordionItemId)
    }
  }

  return (
    <div className="Accordion">
      <dl>
        {data.map((item, index) => {
          return (
            <React.Fragment key={index}>
              <AccordionItem
                fullWidthMode={fullWidthMode}
                title={item.title}
                content={item.content}
                contentClassName="py-mobile-s lg:py-xxs"
                initiallyActive={selectedItem === index}
                onToggle={active => toggleHandler(active, index)}
              />
              <div className="pb-mobile-xxs lg:pb-xxxs border-b border-separator border-opacity-separator"></div>
              <div className="pt-mobile-xxs lg:pt-xxxs"></div>
            </React.Fragment>
          )
        })}
      </dl>
    </div>
  )
}

export default Accordion
