import * as React from 'react'
import { ImageElement } from '@kentico/gatsby-kontent-components'
import { IsMobileContext } from '../../common/Context'
import { ProcessSection as ProcessSectionClass } from './model'
import { AnimationService } from '../../common/AnimationService'
import { ImageQuality } from '../../common/typings/enums'
import './style.scss'

const ProcessSection: React.FC<ProcessSectionClass> = ({
  title,
  imageDesktop,
  videoDesktopUrl,
  imageMobile,
  videoMobileUrl,
}) => {

  const titleRef = React.useRef<HTMLDivElement>(null)
  const imageRef = React.useRef<HTMLDivElement>(null)
  React.useEffect(() => {
    AnimationService.slideUp(titleRef.current)
    AnimationService.slideUp(imageRef.current)
  }, [])

  const { isMobile } = React.useContext(IsMobileContext)
  const image = isMobile ? imageMobile : imageDesktop
  const videoUrl = isMobile ? videoMobileUrl : videoDesktopUrl
  const imageQualityOptions = React.useMemo(() => ({ quality: ImageQuality.OPTIMIZED }), [])

  return (
    <section className="ProcessSection GlobSectPadSpacing px-mobile-xs lg:px-m">
      <div className="SpacingAdjust pt-mobile-l lg:pt-l"></div>
      <div ref={titleRef} className="FontL mb-mobile-m lg:mb-l">
        {title}
      </div>
      <div ref={imageRef}>
        {image && (
          <ImageElement
            image={image}
            layout="fullWidth"
            alt={image.description || title}
            options={imageQualityOptions}
          />
        )}
        {videoUrl && (
          <video autoPlay muted loop playsInline className='Video overflow-hidden flex object-cover'>
            <source
              src={videoUrl}
              type="video/mp4"
            />
          </video>
        )}
      </div>
    </section>
  )
}

export default ProcessSection
