import * as React from 'react'
import { RichTextElement } from '@kentico/gatsby-kontent-components'
import _isFunction from 'lodash/isFunction'
import _isUndefined from 'lodash/isUndefined'
import PlusMinusIcon from '../PlusMinusIcon'
import { AccordionItem as AccordionItemClass } from './model'
import { IsMobileContext } from '../../../common/Context'
import './style.scss'

export interface AccordionItemProps extends AccordionItemClass {
  className?: string
  contentClassName?: string
  onToggle?: (active: boolean) => void
  initiallyActive?: boolean
  fullWidthMode?: boolean
}

const AccordionItem: React.FC<AccordionItemProps> = ({
  title,
  content,
  className,
  contentClassName,
  children,
  onToggle,
  initiallyActive,
  fullWidthMode = false
}) => {
  const { isMobile } = React.useContext(IsMobileContext)
  const contentDiv = React.useRef<HTMLDivElement>(null)
  const [isActive, setIsActive] = React.useState(false)
  const [contentDivHeight, setContentDivHeight] = React.useState('0px')

  const fullWidthDesktopMode = fullWidthMode && isMobile === false
  const contentCSS = fullWidthDesktopMode ? 'lg:py-m' : contentClassName

  React.useEffect(() => {
    if (!_isUndefined(initiallyActive)) {
      setIsActive(initiallyActive)
      setContentDivHeight(initiallyActive ? `${contentDiv.current?.scrollHeight}px` : '0px')
    }
  }, [initiallyActive])

  const isExpandableContent = !!content || !!children

  const toggleActive = () => {
    if (isExpandableContent) {
      if (_isFunction(onToggle)) {
        onToggle(!isActive)
      }
      setIsActive(prevState => !prevState)
      setContentDivHeight(isActive ? '0px' : `${contentDiv.current?.scrollHeight}px`)
    }
  }

  return (
    <div className={`AccordionItem FontM grid grid-cols-12 ${className || ''}`}>
      <dt className="col-span-12">
        <button
          onClick={toggleActive}
          className={`w-full flex items-center justify-between overflow-hidden ${isExpandableContent ? 'cursor-pointer' : 'cursor-default'}`}
        >
          <div className='text-left'>{title}</div>
          {isExpandableContent && <PlusMinusIcon active={isActive} />}
        </button>
      </dt>
      <dd
        ref={contentDiv}
        className={`overflow-hidden transition-all duration-500 ease-in-out ${fullWidthDesktopMode ? 'col-span-10 col-start-2' : 'col-span-12'}`}
        style={{ maxHeight: `${contentDivHeight}` }}
      >
        {children
          ? (
            <div className={`${contentCSS || ''}`}>
              {children}
            </div>
          )
          : (
            <div className={`${contentCSS || ''}`}>
              <RichTextElement value={content} />
            </div>
          )
        }
      </dd>
    </div>
  )
}

export default AccordionItem
