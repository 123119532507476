import * as React from 'react'
import AccordionColumn from '../AccordionSection/AccordionColumn'
import { CategoryAccordionSection as CategoryAccordionSectionClass } from './model'
import { AnimationService } from '../../common/AnimationService'
import { IsMobileContext } from '../../common/Context'
import './style.scss'

const CategoryAccordionSection: React.FC<CategoryAccordionSectionClass> = ({
  category,
  columns,
}) => {

  const { isMobile } = React.useContext(IsMobileContext)

  const headingRef = React.useRef<HTMLDivElement>(null)
  const columnsRefs = React.useRef<Array<HTMLDivElement>>([])
  React.useEffect(() => {
    if (isMobile !== undefined) {
      const elementsToAnimate = [headingRef.current, ...columnsRefs.current]
      if (isMobile) {
        for (const el of elementsToAnimate) {
          AnimationService.slideUp(el)
        }
      } else {
        AnimationService.slideSequenceUp(elementsToAnimate)
      }
    }
  }, [isMobile])

  return (
    <section className="CategoryAccordionSection GlobSectPadSpacing px-m">
      <div className="SpacingAdjust pt-mobile-l lg:pt-l"></div>
      <div className="grid grid-col-1 lg:grid-cols-3 gap-x-m">
        <div ref={headingRef}>
          {!isMobile && <div className="hidden lg:block FontS lg:pt-s lg:pb-s">&nbsp;</div>}
          <div className="block lg:hidden pt-mobile-m"></div>
          <div className="FontL pb-mobile-l lg:pb-0">{category}</div>
        </div>
        {columns.map((column, i) => (
          <div key={i} className={i > 0 ? 'pt-mobile-l lg:pt-0' : undefined}>
            <AccordionColumn
              key={i}
              ref={(el: HTMLDivElement) => columnsRefs.current[i] = el}
              title={column.title}
              text={column.text}
              items={column.items}
            />
          </div> 
        ))}
      </div>
    </section>
  )
}

export default CategoryAccordionSection
