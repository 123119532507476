import { graphql, useStaticQuery } from 'gatsby'
import { filterPageDataByLanguage } from '../../common/serviceHelpers'
import { AccordionItem, KenticoAccordionItemElement } from '../Accordion/AccordionItem/model'
import _get from 'lodash/get'

const accordionColumnsElements = (queryData: KenticoAccordionSection): AccordionColumn[] => {
  const COLUMNS_NUMBER = 3
  const elements = []
  for (let i = 1; i <= COLUMNS_NUMBER; i++) {
    const element = {
      title: _get(queryData, `column_${i}_title.value`, ''),
      text: _get(queryData, `column_${i}_leading.value`, ''),
      items: _get(queryData, `column_${i}_items.value`, [])
        .filter(Boolean)
        .map((element: KenticoAccordionItemElement) => {
          return new AccordionItem(element)
        })
    }
    elements.push(element)
  }
  return elements
}

/* Kentico model: Accordion Section */
export interface KenticoAccordionSection extends KontentItem {
  title: KontentTextElement
  leading: KontentTextElement
  background_color: KontentTextElement
  column_1_title: KontentTextElement
  column_1_leading: KontentTextElement
  column_1_items: KenticoAccordionItemElement[]
  column_2_title: KontentTextElement
  column_2_leading: KontentTextElement
  column_2_items: KenticoAccordionItemElement[]
  column_3_title: KontentTextElement
  column_3_leading: KontentTextElement
  column_3_items: KenticoAccordionItemElement[]
}

export interface AccordionColumn {
  title: string
  text: string
  items: AccordionItem[]
  titleClass?: string
  textClass?: string
}

export class AccordionSection {
  title: string
  text: string
  backgroundColor: string
  columns: AccordionColumn[]

  constructor(data: KenticoAccordionSection) {
    this.title = _get(data, 'title.value', '')
    this.text = _get(data, 'leading.value', '')
    this.backgroundColor = _get(data, 'background_color.value', '')
    this.columns = accordionColumnsElements(data)
  }
}

const accordionSectionFragment = graphql`
query AccordionSectionQuery {
  allKontentItemPage {
    nodes {
      elements {
        content {
          value {
            ... on kontent_item_accordion_section {
              elements {
                ...kontent_item_accordion_section_elementsFragment
              }
              preferred_language
              system {
                codename
              }
            }
          }
        }
      }
      system {
        codename
      }
    }
  }
}`

export const accordionSectionElementsFragment = graphql`
  fragment kontent_item_accordion_section_elementsFragment on kontent_item_accordion_section_elements {
    column_1_title {
      value
    }
    column_1_leading {
      value
    }
    background_color {
      value
    }
    column_1_items {
      ...accordion_column_itemFragment
    }
    column_2_title {
      value
    }
    column_2_leading {
      value
    }
    column_2_items {
      ...accordion_column_itemFragment
    }
    column_3_title {
      value
    }
    column_3_leading {
      value
    }
    column_3_items {
      ...accordion_column_itemFragment
    }
    title {
      value
    }
    leading {
      value
    }
  }
`

export const useAccordionSectionData = (language: string, pageCodename: string, sectionCodename = '') => {
  const data = useStaticQuery(accordionSectionFragment)
  const accordionSectionData: KenticoAccordionSection = filterPageDataByLanguage(data.allKontentItemPage, pageCodename, sectionCodename, language)
  return accordionSectionData 
    ? new AccordionSection(accordionSectionData)
    : null
}