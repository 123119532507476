import * as React from 'react'
import './style.scss'
import Accordion from '../../Accordion'
import { AccordionColumn as AccordionColumnProps } from '../model'

const AccordionColumn = React.forwardRef<HTMLDivElement, AccordionColumnProps>((props: AccordionColumnProps, ref) => {
  const { title, text, items, titleClass = 'FontS', textClass = 'FontL' } = props
  return (
    <div ref={ref} className="AccordionColumn">
      <div className={`${titleClass} lg:pt-s pb-mobile-s lg:pb-s`}>{title}</div>
      <div className={`${textClass} pb-mobile-s lg:pb-s`}>{text}</div>
      {items.length > 0 &&
        <>
          <div className="pb-mobile-xxs lg:pb-xxxs border-b border-separator border-opacity-separator"></div>
          <div className="pt-mobile-xxs lg:pt-xxxs"></div>
        </>
      }
      <Accordion data={items} fullWidthMode={false} />
    </div>
  )
})
AccordionColumn.displayName = 'AccordionColumn'

export default AccordionColumn
