import { graphql, useStaticQuery } from 'gatsby'
import { filterSectionsByLanguage } from '../../common/serviceHelpers'
import { AccordionItem, KenticoAccordionItemElement } from '../Accordion/AccordionItem/model'
import _get from 'lodash/get'

const accordionColumnsElements = (queryData: KenticoCategoryAccordionSection): AccordionColumn[] => {
  const COLUMNS_NUMBER = 2
  const elements = []
  for (let i = 1; i <= COLUMNS_NUMBER; i++) {
    const element = {
      title: _get(queryData, `column_${i}_title.value`, ''),
      text: _get(queryData, `column_${i}_leading.value`, ''),
      items: _get(queryData, `column_${i}_items.value`, [])
        .filter(Boolean)
        .map((element: KenticoAccordionItemElement) => {
          return new AccordionItem(element)
        })
    }
    elements.push(element)
  }
  return elements
}

/* Kentico model: Category Accordion Section */
export interface KenticoCategoryAccordionSection extends KontentItem {
  category_name: KontentTextElement
  column_1_title: KontentTextElement
  column_1_leading: KontentTextElement
  column_1_items: KenticoAccordionItemElement[]
  column_2_title: KontentTextElement
  column_2_leading: KontentTextElement
  column_2_items: KenticoAccordionItemElement[]
}

interface KenticoCategoryAccordionSections {
  elements: KenticoCategoryAccordionSection
  preferred_language: string
  system: {
    codename: string
  }
}

interface AccordionColumn {
  title: string
  text: string
  items: AccordionItem[]
}

export class CategoryAccordionSection {
  category: string
  columns: AccordionColumn[]

  constructor(data: KenticoCategoryAccordionSection) {
    this.category = _get(data, 'category_name.value', '')
    this.columns = accordionColumnsElements(data)
  }
}

const categoryAccordionSectionFragment = graphql`
query CategoryAccordionSectionQuery {
  allKontentItemPage {
    nodes {
      elements {
        content {
          value {
            ... on kontent_item_category_accordion_section {
              elements {
                ...kontent_item_category_accordion_section_elementsFragment
              }
              preferred_language
              system {
                codename
              }
            }
          }
        }
      }
      system {
        codename
      }
    }
  }
}`

export const categoryAccordionSectionElementsFragment = graphql`
  fragment kontent_item_category_accordion_section_elementsFragment on kontent_item_category_accordion_section_elements {
    column_1_title {
      value
    }
    column_1_leading {
      value
    }
    column_1_items {
      ...accordion_column_itemFragment
    }
    column_2_title {
      value
    }
    column_2_leading {
      value
    }
    column_2_items {
      ...accordion_column_itemFragment
    }
    category_name {
      value
    }
  }
`

export const useAllCategoryAccordionSectionsData = (language: string, pageCodename: string) => {
  const data = useStaticQuery(categoryAccordionSectionFragment)
  const allCategoryAccordionSectionsData: KenticoCategoryAccordionSections[] = filterSectionsByLanguage(data.allKontentItemPage, pageCodename, language)
  return allCategoryAccordionSectionsData.map(sectionData => new CategoryAccordionSection(sectionData.elements))
}
